import { fetchCreateComment } from "api/posts";
import { Button, Input } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";

export const CreateComment = ({
  userId,
  postId,
  className,
  getAllPosts,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const sendCreateComment = async (e) => {
    e.preventDefault();
    if (comment.length === 0) return;
    setLoading(true);
    await fetchCreateComment(comment, postId);
    setComment("");
    await getAllPosts();
    setLoading(false);
  };

  return (
    <form
      className={joinClasses(
        "flex flex-col md:flex-row gap-2 md:gap-5 mt-3",
        className
      )}
      onSubmit={sendCreateComment}
      {...props}
    >
      <Input
        placeholder="Message..."
        className="w-full text-[14px]"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />
      {loading ? (
        <Button className="w-max opacity-50 cursor-not-allowed">
          Chargement...
        </Button>
      ) : (
        <Button
          type="submit"
          className={joinClasses(
            "w-max",
            comment.length === 0 ? "opacity-50 cursor-not-allowed" : ""
          )}
        >
          Envoyer
        </Button>
      )}
    </form>
  );
};
