import joinClasses from "helpers/joinClasses";
import { useState } from "react";

export const FilePreviw = ({
  preview = "",
  setPreview = () => {},
  className,
  onChange = () => {},
  ...props
}) => {
  // function convert file image in base64
  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const [fileName, setFileName] = useState(null);
  const handleChange = async (e) => {
    if (e.target.files[0].name) {
      setPreview(await convertFileToBase64(e.target.files[0]));
      setFileName(e.target.files[0].name);
      onChange(e);
    }
  };

  return (
    <div className="flex gap-2 items-center ">
      <div className="relative h-[140px] w-[140px] overflow-hidden cursor-pointer border-4 border-grey-light rounded-full">
        <div
          className="w-full h-full bg-grey-light rounded-full flex justify-center items-center cursor-pointer bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url('${preview}')` }}
        >
          {preview.length ? (
            <i className="i image !text-[60px] text-black-light opacity-50" />
          ) : (
            <i className="i image !text-[26px] text-black-light" />
          )}
        </div>
        <input
          onChange={handleChange}
          type="file"
          accept="image/png, image/jpeg"
          {...props}
          className="opacity-0 absolute top-0 right-0 bottom-0 left-0 cursor-pointer w-full h-full"
        />
      </div>
    </div>
  );
};
