import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCheckToken,
  fetchEditUser,
  fetchLogin,
  fetchLogout,
  fetchUserInfo,
} from "api/auth";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
  token: cookies.get("::token") || null,
  error: "",
  status: "done",
  user: null,
};

export const changeUserInfo = createAsyncThunk(
  "user/edit",
  async (user, { getState }) => {
    const state = getState();
    const response = await fetchEditUser(user, state.user.user.user_id);
    return response;
  }
);

export const getUserInfo = createAsyncThunk("user/login", async (user) => {
  const response = await fetchLogin(user);
  return { response: response, oldInput: user };
});

export const logoutUser = createAsyncThunk("user/logout", async (user) => {
  const response = await fetchLogout();
  return response;
});

export const checkUserToken = createAsyncThunk(
  "user/checkToken",
  async (arg, { getState }) => {
    const state = getState();

    const response = await fetchCheckToken(state.user.token);
    if (response.response) {
      if (!state.user.user) {
        return response;
      }
      return;
    }
    return response;
  }
);

export const setUserInfo = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeUserInfo: (state) => {
      state.token = null;
      state.error = "";
      state.status = "done";
      state.user = {
        id: "",
        email: "",
        firstname: "",
        lastname: "",
        role: "",
        status: "",
      };
      cookies.remove("::token");
    },
    addUserInfo: (state, action) => {
      state.error = "";
      state.status = "done";
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
        cookies.set("::token", action.payload.token, {
          path: "/",
        });
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(changeUserInfo.pending, (state) => {
        state.error = "";
        state.update = "loading";
      })
      .addCase(changeUserInfo.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
          state.update = "done";
        } else if (action.payload) {
          state.error = "";
          state.status = "done";
          state.update = "done";
          state.user = action.payload.response.user || action.payload;
        }
      });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      if (action.payload && action.payload.status === "error") {
        state.error = action.payload.response;
        state.status = "done";
      } else if (action.payload) {
        state.error = "";
        state.status = "done";
        state.user = null;
        state.token = null;
        cookies.remove("::token");
      }
    });
    builder
      .addCase(checkUserToken.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(checkUserToken.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
          state.status = "done";
          state.update = "done";
        } else if (action.payload) {
          state.error = "";
          state.status = "done";
          state.user = action.payload.response;
        }
      });
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        if (
          action.payload.response.status &&
          action.payload.response.status === "error"
        ) {
          state.error = "Mauvais Email ou Mot de passe.";
          state.status = "done";
          state.update = "done";
        } else {
          state.error = "";
          state.status = "done";
          state.token = action.payload.response.response.user.token;
          state.user = action.payload.response.response.user;
          cookies.set("::token", action.payload.response.response.user.token, {
            path: "/",
          });
        }
      });
  },
});

export const { removeUserInfo, addUserInfo } = setUserInfo.actions;

export const userInfo = (state) => state.user;

export default setUserInfo.reducer;
