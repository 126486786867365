import { fetchUserPublic } from "api/auth";
import { Avatar, Paragraph, Title } from "components/atoms";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { userInfo } from "store/models/userInfo";
import { DefaultTemplate } from "templates/Default";

export const Profile = () => {
  let { id } = useParams();
  const state = useSelector(userInfo);
  const [user, setUser] = useState(null);

  const getUserById = async (id) => {
    const response = await fetchUserPublic(id);
    console.log("response -> ", response);
    if (response.status === "error") setUser("NOT_FOUND");
    else setUser(response.response[0]);
  };

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [id]);

  useEffect(() => {
    if (user && user.first_name) {
      document.title = `Profil de ${user.first_name} - Kodi`;
    } else {
      document.title = "Profil - Kodi";
    }
  }, [user]);

  const history = useHistory();

  return (
    <DefaultTemplate user={state.user}>
      {user ? (
        <div className="py-5">
          <button
            className="flex items-center gap-1 mb-2"
            onClick={() => history.push("/")}
          >
            <i className="i back" /> retour
          </button>
          {user === "NOT_FOUND" ? (
            <div>
              <Title className="text-center">Aucun profil trouvé</Title>
            </div>
          ) : (
            <>
              <Title className="text-center md:text-left mb-5">{`${user.first_name} ${user.last_name[0]}.`}</Title>
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <Avatar
                  picture={user.img_url || "/assets/avatar.svg"}
                  className="h-[140px] w-[140px] border-4 border-grey-light"
                />
                <Paragraph className="text-center md:text-left">
                  {user.biography}
                </Paragraph>
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <Paragraph>Loading...</Paragraph>
        </div>
      )}
    </DefaultTemplate>
  );
};
