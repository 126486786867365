import joinClasses from "helpers/joinClasses";

export const Error = ({ children, className, ...props }) => {
  return (
    <div
      className={joinClasses(
        "bg-red text-white w-full px-5 py-3 text-sm rounded-lg"
      )}
      {...props}
    >
      {children}
    </div>
  );
};
