import { Button, Paragraph } from "components/atoms";
import { Footer } from "components/molecules";
import { useHistory } from "react-router";
import { HomeTemplate } from "templates/Home";

export const Home = () => {
  document.title = "Rejoins une communauté digitale  - Kodi";
  const history = useHistory();
  return (
    <HomeTemplate>
      <div
        className="w-screen h-screen bg-cover bg-bottom"
        style={{ backgroundImage: "url('/assets/bg-banner.svg')" }}
      >
        <div className="flex flex-col-reverse lg:flex-row justify-center lg:justify-between items-center max-w-[1200px] w-full h-full m-auto px-5 gap-6">
          <div className="flex flex-col gap-4 items-center lg:items-start">
            <h1 className="font-black text-[30px] md:text-[68px] text-center lg:text-left">
              Rejoins une communauté digitale
            </h1>
            <Button
              className="w-max"
              onClick={() => history.push("auth/register")}
            >
              Commencer
            </Button>
          </div>
          <img
            src="/assets/illustration.svg"
            alt=""
            className="max-w-[400px] lg:w-max"
          />
        </div>

        <div className="w-full min-h-[650px] relative">
          <img
            src="/assets/decor-elem.svg"
            alt=""
            className="absolute top-40 scale-50 md:scale-100 -translate-x-2/4 md:-translate-x-1/4 z-0"
          />
          <img
            src="/assets/decor-elem.svg"
            alt=""
            className="absolute right-0 bottom-10 scale-50 md:scale-100 translate-x-2/4 md:translate-x-2/4 z-0"
          />
          <div className="max-w-[1200px] w-full min-h-[600px] h-full z-30 flex flex-col lg:flex-row  items-center jusfity-center m-auto gap-8 ">
            <div className="backdrop-blur-lg w-8/12 text-center gap-5 lg:w-full flex flex-col items-center p-8 rounded-2xl">
              <img src="/assets/connect.svg" alt="" />
              <h3 className="font-black text-black-light w-9/12">
                Connecte toi avec le monde entier
              </h3>
            </div>
            <div className="backdrop-blur-lg w-8/12 text-center gap-5 lg:w-full flex flex-col items-center p-8 rounded-2xl">
              <img src="/assets/relations.svg" alt="" />
              <h3 className="font-black text-black-light w-9/12">
                Étend tes relations profesionnelles
              </h3>
            </div>
            <div className="backdrop-blur-lg w-8/12 text-center gap-5 lg:w-full flex flex-col items-center p-8 rounded-2xl">
              <img src="/assets/collaborate.svg" alt="" />
              <h3 className="font-black text-black-light w-9/12">
                Favorise la collaboration et la productivité
              </h3>
            </div>
          </div>
        </div>

        <div
          className="w-full min-h-[500px] relative bg-cover bg-center flex flex-col gap-5 justify-center items-center"
          style={{ backgroundImage: "url('/assets/banner-cta.svg')" }}
        >
          <h3 className="font-black text-blue-darkest text-[30px] md:text-[50px] text-center">
            Commence dès maintenant
          </h3>
          <Button
            className="w-max !bg-white text-black shadow-lg"
            onClick={() => history.push("auth/register")}
          >
            Commencer
          </Button>
        </div>
        <div className="flex flex-col md:flex-row gap-10 items-center justify-center py-20">
          <img
            src="/assets/mockup.png"
            alt="screen du fil d'actualité"
            className="max-w-[400px] hidden md:block"
          />
          <ul className="flex flex-col gap-10 font-semibold	">
            <li className="flex items-center gap-3">
              <div className="h-[9px] w-[9px] bg-blue rounded-full" />
              <Paragraph>Partage tes passions et centres d’intérêt</Paragraph>
            </li>
            <li className="flex items-center gap-3">
              <div className="h-[9px] w-[9px] bg-blue rounded-full" />
              <Paragraph>Personnalise ton profil selon tes envies</Paragraph>
            </li>
            <li className="flex items-center gap-3">
              <div className="h-[9px] w-[9px] bg-blue rounded-full" />
              <Paragraph>Réagis et commente les derniers posts</Paragraph>
            </li>
            <li className="flex items-center gap-3">
              <div className="h-[9px] w-[9px] bg-blue rounded-full" />
              <Paragraph>Créée ton propre réseau digital</Paragraph>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </HomeTemplate>
  );
};
