import joinClasses from "helpers/joinClasses";

export const Card = ({ children, className, ...props }) => {
  return (
    <div
      className={joinClasses(
        "z-20 px-10 md:px-16 w-full bg-white shadow-md rounded-xl py-5",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
