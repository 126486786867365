import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import { Login } from "pages/Auth/login";
import { Home } from "pages/Home";
import { Register } from "../pages/Auth/register";
import { Legals } from "pages/Legals";

export default function AuthenticationRouter() {
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/auth/register" component={Register} />
          <Route exact path="/" component={Home} />
          <Route exact path="/mentions-legales" component={Legals} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}
