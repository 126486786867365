import axios from "axios";
import { getErrorMessage } from "./errors";

axios.defaults.baseURL = process.env.REACT_APP_API;

export const axiosRequest = async (axiosParams) => {
  try {
    const response = await axios.request(axiosParams);
    return { status: "done", response: response.data };
  } catch (error) {
    const { code } = error.response.data;

    return {
      status: "error",
      response: "",
    };
  }
};
