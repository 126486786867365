import Loader from "pages/_loader";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkUserToken, userInfo } from "store/models/userInfo";

import AuthenticationRouter from "routers/authentication";
import DefaultRouter from "./routers/default";

function App() {
  const dispatch = useDispatch();
  const state = useSelector(userInfo);

  useEffect(() => {
    dispatch(checkUserToken());
  }, [dispatch]);

  if (state.status === "loading") {
    return <Loader />;
  }
  if (!state.token || !state.user) {
    return <AuthenticationRouter />;
  }
  return <DefaultRouter />;
}

export default App;
