import { Paragraph } from "components/atoms";
import { Author } from "../Author";
import { Like } from "./Like";

export const Comment = ({
  data: { comment, author, likes },
  user,
  getAllPosts,
  postId = 0,
}) => {
  return (
    <div>
      <Author user={author} createdAt={comment.created_at} />
      <Paragraph>{comment.body}</Paragraph>
      <Like
        likes={likes}
        count={likes.length}
        userId={user && user.user_id}
        postId={postId}
        commentId={comment && comment.id}
        className="my-2"
        primaryLike={false}
        getAllPosts={getAllPosts}
      />
    </div>
  );
};
