import { Title } from "components/atoms";
import { useSelector } from "react-redux";
import { TimelineTemplate } from "templates/Timeline";
import { CreatePost } from "./CreatePost";
import { Post } from "./Post";
import { userInfo } from "store/models/userInfo";
import { useEffect, useState } from "react";
import { fetchPosts } from "api/posts";

document.title = "Timeline - Kodi";

export const Timeline = () => {
  const [posts, setPosts] = useState([]);
  const state = useSelector(userInfo);
  const getAllPosts = async () => {
    const { response } = await fetchPosts();
    setPosts(response);
  };
  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <TimelineTemplate user={state.user}>
      <CreatePost getAllPosts={getAllPosts} />
      <Title className="mt-5 mb-3">Timeline</Title>
      <div className="flex flex-col gap-8">
        {posts.length ? (
          posts.map((post) => (
            <Post
              key={post.posts.id}
              data={post}
              user={state.user}
              getAllPosts={getAllPosts}
            />
          ))
        ) : (
          <p>Aucune publication.</p>
        )}
      </div>
    </TimelineTemplate>
  );
};
