import { Button, Error, Input, Title } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getUserInfo, userInfo } from "store/models/userInfo";
import { DefaultTemplate } from "templates/Default";

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userStore = useSelector(userInfo);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  document.title = "Connexion - Kodi";

  const sendLogin = async (e) => {
    e.preventDefault();
    if (user.email.length === 0 || user.password.length === 0) return;
    const response = await dispatch(getUserInfo(user));
    if (response.payload.response.status === "done") {
      history.push("/");
      window.location.reload();
    }
  };

  return (
    <DefaultTemplate>
      <Title className="text-center">Connexion</Title>
      <form
        className="mt-10 flex flex-col gap-5 items-end"
        onSubmit={sendLogin}
      >
        <Input
          className="w-full"
          type="email"
          placeholder="Adresse mail"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          required
        />
        <Input
          className="w-full"
          type="password"
          placeholder="Mot de passe"
          required
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        {userStore.error && <Error>{userStore.error}</Error>}
        <Button
          type="submit"
          className={joinClasses(
            "w-max",
            user.email.length === 0 || user.password.length === 0
              ? "opacity-50 cursor-not-allowed"
              : ""
          )}
        >
          Valider
        </Button>
      </form>
    </DefaultTemplate>
  );
};
