import { fetchLikeComment, fetchLikePost } from "api/posts";
import { Loader, Paragraph } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";

export const Like = ({
  likes,
  className,
  count = 0,
  userId = 0,
  postId = 0,
  commentId = 0,
  primaryLike = true,
  getAllPosts,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const sendLike = async () => {
    if (primaryLike) {
      setLoading(true);
      await fetchLikePost(postId);
      await getAllPosts();
      setLoading(false);
    } else {
      setLoading(true);
      await fetchLikeComment(postId, commentId);
      await getAllPosts();
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <button
          className={joinClasses(
            "flex items-center justify-center px-3 rounded-full gap-3 font-bold bg-grey-light text-[14px]",
            className,
            likes.find((like) => like.user_id === userId)
              ? "text-blue "
              : "text-black-light"
          )}
        >
          <Loader />
        </button>
      ) : (
        <button
          {...props}
          onClick={sendLike}
          className={joinClasses(
            "flex items-center justify-center px-3 rounded-full gap-3 font-bold bg-grey-light text-[14px]",
            className,
            likes.find((like) => like.user_id === userId)
              ? "text-blue "
              : "text-black-light"
          )}
        >
          <i className="i like" />

          <Paragraph>{!primaryLike && <span>{count}</span>} J'aime</Paragraph>
        </button>
      )}
    </>
  );
};
