import joinClasses from "helpers/joinClasses";

export const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={joinClasses(
        "font-semibold montserrat-font bg-blue rounded-lg text-white py-3 px-8 hover:bg-blue-dark",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
