import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-black-ligth mt-3 text-white relative">
      <div className="flex flex-col gap-3 md:flex-row justify-between py-5 px-10 md:px-16 max-w-[1600px] m-auto items-center z-30">
        <p>Copyright © 2022 Kodi inc.</p>
        <Link className="underline" to="/mentions-legales">
          Mentions légales
        </Link>
      </div>
    </footer>
  );
};
