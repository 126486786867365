import { Footer, Navbar } from "components/molecules";
import joinClasses from "helpers/joinClasses";

export const TimelineTemplate = ({
  children,
  className = "",
  user = null,
  ...props
}) => {
  return (
    <div
      className={joinClasses(
        "bg-blue-lightest min-h-screen relative max-w-screen overflow-x-hidden montserrat-font",
        className
      )}
      {...props}
    >
      <Navbar user={user} fixedOnScroll />
      <div className="z-20 px-3 md:px-16 max-w-[970px] m-auto w-full py-3 md:py-5">
        {children}
      </div>
      <Footer />
    </div>
  );
};
