import joinClasses from "helpers/joinClasses";

export const Title = ({ children, className, ...props }) => {
  return (
    <h1
      className={joinClasses(
        "text-[30px] font-bold monterrat-font text-black-ligth",
        className
      )}
      {...props}
    >
      {children}
    </h1>
  );
};
