import { fetchRegister } from "api/auth";
import { Button, Input, Title } from "components/atoms";
import { useState } from "react";
import { useHistory } from "react-router";
import { DefaultTemplate } from "templates/Default";

export const Register = () => {
  const history = useHistory();
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  document.title = "Inscription - Kodi";

  const sendRegistration = async (e) => {
    e.preventDefault();
    if (user.password !== user.password_confirmation) {
      return alert("Les mots de passe ne correspondent pas");
    }
    await fetchRegister(user);
    history.push("/auth/login");
  };

  return (
    <DefaultTemplate>
      <Title className="text-center">Inscription</Title>
      <form
        className="mt-10 flex flex-col gap-5 items-end"
        onSubmit={sendRegistration}
      >
        <div className="flex gap-5 w-full">
          <Input
            className="w-full"
            type="text"
            placeholder="Prénom"
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            required
          />
          <Input
            className="w-full"
            type="text"
            placeholder="Nom"
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            required
          />
        </div>
        <Input
          className="w-full"
          type="email"
          placeholder="Adresse mail"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          required
        />
        <Input
          className="w-full"
          type="password"
          placeholder="Mot de passe"
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          required
        />
        <Input
          className="w-full"
          type="password"
          placeholder="Confirmer mot de passe"
          onChange={(e) =>
            setUser({ ...user, password_confirmation: e.target.value })
          }
          required
        />
        <Button type="submit" className="w-max">
          Valider
        </Button>
      </form>
    </DefaultTemplate>
  );
};
