import joinClasses from "helpers/joinClasses";

export const Avatar = ({
  picture = "/assets/avatar.svg",
  className,
  ...props
}) => {
  return (
    <img
      className={joinClasses(
        "h-[48px] w-[48px] rounded-full object-cover",
        className
      )}
      src={picture}
      alt=""
      {...props}
    />
  );
};
