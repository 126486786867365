import {
  Button,
  Error,
  FilePreviw,
  Input,
  Textarea,
  Title,
} from "components/atoms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DefaultTemplate } from "templates/Default";
import { logoutUser, userInfo, changeUserInfo } from "store/models/userInfo";
import joinClasses from "helpers/joinClasses";

export const Account = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector(userInfo);
  const [user, setUser] = useState(userState.user);

  const [preview, setPreview] = useState("");

  useEffect(() => {
    setPreview(user.img_url || "/assets/avatar.svg");
    // eslint-disable-next-line
  }, []);

  document.title = "Mon compte - Kodi";

  useEffect(() => {
    console.log("userState -> ", userState.user);
  }, [userState]);

  const sendEditUser = async (e) => {
    e.preventDefault();
    if (user === userState.user) return;
    await dispatch(changeUserInfo(user));

    setUser(userState.user);
  };
  return (
    <DefaultTemplate user={userState.user}>
      <div className="flex justify-between">
        <button
          className="flex items-center gap-1 mb-2"
          onClick={() => history.push("/")}
        >
          <i className="i back" /> retour
        </button>
        <Button
          onClick={() => dispatch(logoutUser())}
          className="!px-3 !py-2 text-sm !bg-red"
        >
          Déconnexion
        </Button>
      </div>
      <Title className="text-center md:text-left mb-5">{`${userState.user.first_name} ${userState.user.last_name[0]}.`}</Title>
      <form
        className="mt-10 flex flex-col gap-5 items-end"
        onSubmit={sendEditUser}
      >
        <div className="flex flex-col md:flex-row gap-4 items-center w-full">
          <FilePreviw
            preview={preview}
            setPreview={setPreview}
            onChange={(e) => setUser({ ...user, avatar: e.target.files[0] })}
          />
          <Textarea
            className="text-center md:text-left w-full !px-4"
            onChange={(e) => setUser({ ...user, biography: e.target.value })}
          >
            {(user.biography && user.biography) ||
              `Biographie de ${userState.user.first_name} ${userState.user.last_name[0]}.`}
          </Textarea>
        </div>
        <div className="w-full my-3">
          <hr />
        </div>
        <div className="flex gap-5 w-full">
          <Input
            className="w-full"
            type="text"
            placeholder="Prénom"
            defaultValue={user.first_name}
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <Input
            className="w-full"
            type="text"
            placeholder="Nom"
            defaultValue={user.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
        </div>
        <Input
          className="w-full"
          type="email"
          placeholder="Adresse mail"
          defaultValue={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        {userState.error && <Error>{userState.error}</Error>}
        {userState.update === "loading" ? (
          <Button className="w-max opacity-50 cursor-not-allowed">
            Chargement...
          </Button>
        ) : (
          <Button
            type="submit"
            className={joinClasses(
              "w-max",
              user === userState.user && "opacity-50 cursor-not-allowed"
            )}
          >
            Valider
          </Button>
        )}
      </form>
    </DefaultTemplate>
  );
};
