import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { Paragraph } from "..";

export const File = ({
  preview = "",
  iconSize = "26px",
  setFileName,
  fileName,
  className,
  onChange = () => {},
  ...props
}) => {
  const handleChange = (e) => {
    if (e.target.files[0].name) {
      setFileName(e.target.files[0].name);
      onChange(e);
    }
  };

  return (
    <div className="flex gap-2 items-center ">
      {fileName && preview.length === 0 && <Paragraph>{fileName}</Paragraph>}
      <div
        className={joinClasses(
          "relative h-[51px] w-[51px] overflow-hidden cursor-pointer",
          className
        )}
      >
        <div
          className="w-full h-full bg-grey-light rounded-full flex justify-center items-center cursor-pointer bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url('${preview}')` }}
        >
          {preview.length ? (
            <i className="i image !text-[60px] text-black-light opacity-50" />
          ) : (
            <i className="i image !text-[26px] text-black-light" />
          )}
        </div>
        <input
          onChange={handleChange}
          type="file"
          accept="image/png, image/jpeg"
          {...props}
          className="opacity-0 absolute top-0 right-0 bottom-0 left-0 cursor-pointer w-full h-full"
        />
      </div>
    </div>
  );
};
