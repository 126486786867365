import { fetchCreatePost } from "api/posts";
import { Button, Card, Error, File, Textarea } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";

export const CreatePost = ({ getAllPosts }) => {
  const [content, setContent] = useState({ message: "", image: null });
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);

  const sendCreatePost = async (e, contentForm) => {
    e.preventDefault();
    if (contentForm.message.length === 0) return;
    if (contentForm.image && contentForm.image.size > 2000000) {
      return setError("Fichier trop volumineux.");
    }
    setLoading(true);
    await fetchCreatePost(contentForm);
    setContent({ message: "", image: null });
    setFileName(null);
    await getAllPosts();
    setError(null);
    setLoading(false);
  };

  return (
    <form onSubmit={(e) => sendCreatePost(e, content)}>
      <Card className="flex flex-col gap-5 !p-6">
        <Textarea
          placeholder="Message..."
          onChange={(e) => setContent({ ...content, message: e.target.value })}
          value={content.message}
        ></Textarea>
        {error && <Error>{error}</Error>}
        <div className="flex justify-end items-center gap-5">
          <File
            setFileName={setFileName}
            fileName={fileName}
            onChange={(e) =>
              setContent({ ...content, image: e.target.files[0] })
            }
          />

          {loading ? (
            <Button className="opacity-50 cursor-not-allowed">
              Chargement...
            </Button>
          ) : (
            <Button
              type="submit"
              className={joinClasses(
                "w-max",
                content.message.length === 0
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              )}
            >
              Envoyer
            </Button>
          )}
        </div>
      </Card>
    </form>
  );
};
