import { Footer, Navbar } from "components/molecules";
import joinClasses from "helpers/joinClasses";

export const DefaultTemplate = ({
  children,
  className = "",
  backBtn = false,
  user = null,
  ...props
}) => {
  return (
    <div
      className={joinClasses(
        "bg-blue-lightest min-h-screen relative w-screen overflow-x-hidden montserrat-font ",
        className
      )}
      {...props}
    >
      <img
        src="/assets/decor-elem.svg"
        alt=""
        className="absolute top-40 scale-50 md:scale-100 -translate-x-2/4 md:-translate-x-1/4"
      />
      <img
        src="/assets/decor-elem.svg"
        alt=""
        className="absolute right-0 bottom-10 scale-50 md:scale-100 translate-x-2/4 md:translate-x-2/4"
      />
      <Navbar user={user} />
      <div className="min-h-screen">
        <div className="z-20 px-10 md:px-16 max-w-[740px] m-auto absolute w-full top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white shadow-md rounded-xl py-5  backdrop-blur-lg">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};
