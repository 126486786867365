import { Account } from "pages/Account";
import { Legals } from "pages/Legals";
import { Profile } from "pages/Profile";
import { Timeline } from "pages/Timeline";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";

export default function DefaultRouter() {
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/profile/:id" component={Profile} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/" component={Timeline} />
          <Route exact path="/mentions-legales" component={Legals} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}
