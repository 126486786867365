import joinClasses from "helpers/joinClasses";

export const Paragraph = ({ children, className, ...props }) => {
  return (
    <p
      className={joinClasses(
        "leading-9 montserrat-font text-black-light truncate",
        className
      )}
      {...props}
    >
      {children}
    </p>
  );
};
