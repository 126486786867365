import { Paragraph, SubTitle, Title } from "components/atoms";
import { useSelector } from "react-redux";
import { userInfo } from "store/models/userInfo";
import { TimelineTemplate } from "templates/Timeline";

export const Legals = () => {
  const state = useSelector(userInfo);
  document.title = "Mentions légales - Kodi";

  return (
    <TimelineTemplate user={state.user}>
      <Title className="mt-5 mb-3">Mentions légales</Title>

      <br />
      <SubTitle>I. INFORMATIONS SUR L’ÉDITEUR DU SITE</SubTitle>
      <Paragraph>
        Le présent site est la propriété de KODI, Société par actions simplifiée
        unipersonnelle au capital social de 10 000 euros, situé 9 rue Yves
        Toudic, 75011 Paris.
      </Paragraph>
      <Paragraph>
        Numéro d’inscription au registre du commerce et des sociétés de Paris :
        814 445 375
      </Paragraph>

      <Paragraph>
        Le directeur de la publication du site est Clément BARBAZA <br />
        Adresse électronique : contact@kodi.com
        <br />
        Téléphone : 01 55 76 90 90
      </Paragraph>

      <br />
      <br />
      <SubTitle>II. INFORMATIONS SUR L’HÉBERGEUR DU SITE</SubTitle>

      <Paragraph>
        L’hébergement du site est fourni par HEROKU, société par actions
        simplifiée situé 2 rue Kellerman, 59100 ROUBAIX <br />
        Adresse électronique : contact@heroku.net
      </Paragraph>

      <br />
      <br />
      <SubTitle>III. PROPRIÉTÉ INTELLECTUELLE</SubTitle>
      <Paragraph>
        La structure générale du site internet de KODI ainsi que les textes,
        graphiques, images, sons et vidéos la composant, sont la propriété KODI
        ou de ses partenaires. Toute représentation et/ou reproduction et/ou
        exploitation partielle ou totale de ce site, par quelque procédé que ce
        soit, sans l’autorisation préalable et par écrit de KODI ou de ses
        partenaires est strictement interdite et serait susceptible de
        constituer une contrefaçon au sens de l’article L 335-2 et suivants de
        Code de la propriété intellectuelle.
      </Paragraph>

      <br />
      <br />
      <SubTitle>IV. DONNÉES PERSONNELLES</SubTitle>

      <Paragraph>
        Conformément à la Loi n°78-17 du 6 janvier 1978 relative à
        l’informatique, aux fichiers et aux libertés ainsi qu’au Règlement
        Européen sur la Protection des Données (Règlement UE 2016/679 du 27
        avril 2016 – ci-après « RGPD » applicable depuis le 25 mai 2018, les
        traitements de données personnelles par KODI sont soumis à la Politique
        de protection des données , consultable sur le site internet.
      </Paragraph>

      <br />
      <br />
      <SubTitle>V. COOKIES</SubTitle>

      <Paragraph>
        « Un cookie est un petit fichier informatique, un traceur. Il permet
        d’analyser le comportement des usagers lors de la visite d’un site
        internet, de l’installation ou utilisation d’un logiciel. Comme la
        plupart des sites internet, notre site internet utilise des cookies.
        Tous nos cookies ont une durée de vie de treize (13) mois maximum. Afin
        d’en savoir plus sur les cookies, vous pouvez consulter la politique des
        cookies que nous avons mis en place» au sein de KODI.
      </Paragraph>

      <br />
      <br />
      <SubTitle>VI. CRÉDITS</SubTitle>
      <Paragraph>
        Crédits Photos : KODI Graphisme & Développement Web : KODI
      </Paragraph>

      <br />
      <br />
      <SubTitle>VII. MAINTENANCE</SubTitle>
      <Paragraph>
        Pour des raisons de maintenance, KODI pourra interrompre l'accès à son
        site et s'efforcera d'en avertir préalablement les utilisateurs.
      </Paragraph>
      <br />
      <br />
      <SubTitle>VIII. MISE À JOUR DES MENTIONS LÉGALES</SubTitle>
      <Paragraph>
        KODI se réserve le droit de modifier ou d’actualiser cette notice
        d’information à tout moment et sans préavis. Dernière mise à jour de ces
        mentions légales : 13/01/2022.
      </Paragraph>
    </TimelineTemplate>
  );
};
