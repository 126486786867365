import { fetchDeletePost, fetchEditPost } from "api/posts";
import { Button, Card, Paragraph, Textarea } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { Author } from "../Author";
import { Comment } from "./Comment";
import { CountInteraction } from "./CountInteraction";
import { CreateComment } from "./CreateComment";
import { Image } from "./Image";
import { Like } from "./Like";

export const Post = ({
  data: { posts, likes, author, comments },
  user,
  getAllPosts,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [postForm, setPostForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const sendDeletePost = async (id) => {
    await fetchDeletePost(id);
    await getAllPosts();
  };
  const sendEditPost = async (e) => {
    if (
      !postForm ||
      postForm.message.length === 0 ||
      postForm.message === posts.body
    )
      return;
    e.preventDefault();
    setLoading(true);
    await fetchEditPost(postForm, posts.id);
    await getAllPosts();
    setOpenDropdown(false);
    setEditMode(false);
    setLoading(false);
    setPostForm(null);
  };

  // fonction pour remplacer les \n par <br>
  const replaceNewLine = (str) => {
    return str.replace(/\n/g, "<br>");
  };
  return (
    <Card id={posts && posts.id} className="relative !p-6" key={posts.id}>
      {author.id === user.user_id && (
        <div className="absolute top-5 right-5">
          {editMode ? (
            <button
              className="flex justify-center gap-2 text-sm"
              onClick={() => {
                setEditMode(false);
                setOpenDropdown(false);
              }}
            >
              Annuler
            </button>
          ) : (
            <button onClick={() => setOpenDropdown(!openDropdown)}>
              <i className="i options" />
            </button>
          )}
          {openDropdown && !editMode && (
            <Card className="absolute right-0 md:-left-3 rounded w-max !py-2 !px-4 flex gap-2 flex-col items-start text-sm">
              <button
                className="flex justify-center gap-2"
                onClick={() => setEditMode(!editMode)}
              >
                Modifier
              </button>
              <div className="w-full h-[1px] bg-grey-light" />
              <button
                className="flex justify-center gap-2 text-red"
                onClick={() => sendDeletePost(posts.id)}
              >
                Supprimer
              </button>
            </Card>
          )}
        </div>
      )}
      {/* {author.id === user.user_id && (
        <div className="absolute right-0">
          <button onClick={() => sendDeletePost(posts.id)}>Delete</button>
          <button onClick={() => setEditMode(!editMode)}>
            {editMode ? "Cancel" : "Edit"}
          </button>
        </div>
      )} */}
      <Author user={author} createdAt={posts.created_at} />
      {editMode ? (
        <form onSubmit={sendEditPost}>
          <div className="flex flex-col gap-5">
            <Textarea
              placeholder="Message..."
              onChange={(e) =>
                setPostForm({ ...postForm, message: e.target.value })
              }
              defaultValue={posts.body}
            ></Textarea>

            <div className="flex justify-end items-center gap-5">
              {loading ? (
                <Button className="opacity-50 cursor-not-allowed">
                  Chargement...
                </Button>
              ) : (
                <Button
                  type="submit"
                  className={joinClasses(
                    !postForm ||
                      postForm.message.length === 0 ||
                      postForm.message === posts.body
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100"
                  )}
                >
                  Modifier
                </Button>
              )}
            </div>
            <Image src={posts.img} className="my-4" />
          </div>
        </form>
      ) : (
        <div className="my-5">
          <Paragraph
            dangerouslySetInnerHTML={{ __html: replaceNewLine(posts.body) }}
          />
          <Image src={posts.img} className="my-4" />
        </div>
      )}
      <Like
        likes={likes}
        count={likes.length}
        userId={user && user.user_id}
        postId={posts && posts.id}
        className="my-2"
        getAllPosts={getAllPosts}
      />
      <hr className="mt-5 mb-0" />
      <CountInteraction likes={likes.length} comments={comments.length} />
      <CreateComment
        postId={posts && posts.id}
        userId={user && user.id}
        className="mb-5"
        getAllPosts={getAllPosts}
      />
      <div className="md:w-11/12 m-auto">
        {comments.map((comment, index) => (
          <>
            <Comment
              key={comment.comment.id}
              data={comment}
              user={user}
              postId={posts && posts.id}
              getAllPosts={getAllPosts}
            />
            {index + 1 < comments.length && <hr className="my-5" />}
          </>
        ))}
      </div>
    </Card>
  );
};
