import { Avatar, Paragraph } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import moment from "moment";
import "moment/locale/fr";
import { useHistory } from "react-router";
moment.locale("fr");

export const Author = ({ user, createdAt, children, className, ...props }) => {
  const history = useHistory();
  return (
    <div className={joinClasses("flex gap-5 mb-3 ", className)}>
      <Avatar
        picture={user.img_url || user.img || "/assets/avatar.svg"}
        className="cursor-pointer"
        onClick={() => history.push(`/profile/${user.id}`)}
      />
      <Paragraph className="flex gap-2 items-center">
        <span
          className="cursor-pointer underline"
          onClick={() => history.push(`/profile/${user.id}`)}
        >{`${user.first_name} ${user.last_name[0]}.`}</span>{" "}
        <span>|</span>
        <span className="font-light italic text-xs">
          {moment(createdAt).fromNow()}
        </span>
      </Paragraph>
    </div>
  );
};
