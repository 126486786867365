import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchPosts() {
  return await axiosRequest({
    method: "GET",
    url: "/auth/posts",
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchCreatePost(content) {
  const formData = new FormData();
  formData.append("body", content.message);
  if (content.image) {
    formData.append("img", content.image);
  }

  return await axiosRequest({
    method: "POST",
    url: "/auth/post",
    headers: {
      Authorization: getLocalToken(),
    },
    data: formData,
  });
}

export async function fetchCreateComment(body, id) {
  return await axiosRequest({
    method: "POST",
    url: `auth/post/comment/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: {
      body: body,
    },
  });
}

export async function fetchLikePost(id) {
  return await axiosRequest({
    method: "POST",
    url: `auth/post/like/post/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchLikeComment(postId, id) {
  return await axiosRequest({
    method: "POST",
    url: `auth/post/like/comment/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: {
      post_id: postId,
    },
  });
}

export async function fetchDeletePost(id) {
  return await axiosRequest({
    method: "DELETE",
    url: `auth/post/delete/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchEditPost(content, id) {
  const formData = new FormData();
  formData.append("body", content.message);
  if (content.image) {
    formData.append("img", content.image);
  }

  return await axiosRequest({
    method: "POST",
    url: `/auth/post/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: formData,
  });
}
