import joinClasses from "helpers/joinClasses";

export const Textarea = ({ type = "text", className, children, ...props }) => {
  return (
    <textarea
      className={joinClasses(
        "bg-grey-light text-[15px] placeholder:text-[18px] placeholder:text-grey montserrat-font rounded-lg py-3 px-8 font-regular placeholder:font-semibold outline-none w-full max-h-[200px] min-h-[100px]",
        className
      )}
      type={type}
      {...props}
    >
      {children}
    </textarea>
  );
};
