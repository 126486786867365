import joinClasses from "helpers/joinClasses";

export const Image = ({ src, className, ...props }) => {
  return (
    <a href={src} target="_blank">
      <img
        src={src}
        alt=""
        {...props}
        className={joinClasses(
          "w-full max-h-[200px] object-contain cursor-pointer",
          className
        )}
      />
    </a>
  );
};
