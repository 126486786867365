import joinClasses from "helpers/joinClasses";

export const Input = ({ type = "text", className, ...props }) => {
  return (
    <input
      className={joinClasses(
        "bg-grey-light text-[18px] placeholder:text-[18px] placeholder:text-grey montserrat-font rounded-lg py-3 px-8 font-regular placeholder:font-semibold outline-none",
        className
      )}
      type={type}
      {...props}
    />
  );
};
