import { Navbar } from "components/molecules";
import joinClasses from "helpers/joinClasses";

export const HomeTemplate = ({ children, className = "", ...props }) => {
  return (
    <div
      className={joinClasses(
        "min-h-screen relative w-screen overflow-x-hidden montserrat-font ",
        className
      )}
      {...props}
    >
      <div className=" absolute top-0 left-0 w-full z-30">
        <Navbar />
      </div>

      <div className="absolute top-0 left-0 w-full">{children}</div>
    </div>
  );
};
