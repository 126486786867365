import { axiosRequest } from "helpers/axios";
import getLocalToken from "helpers/getLocalToken";

export async function fetchLogin(user) {
  const response = await axiosRequest({
    method: "POST",
    url: "/auth/login",
    data: {
      ...user,
    },
  });

  if (response.status === "done") {
    return { ...response, stay_connected: user.stay_connected };
  }

  return response;
}

export async function fetchRegister(user) {
  return await axiosRequest({
    method: "POST",
    url: "/auth/register",
    data: {
      ...user,
    },
  });
}

export async function fetchVerifyEmail(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/verify-email",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchRegisterIp(code, email) {
  return await axiosRequest({
    method: "POST",
    url: "/authentication/register-ip",
    data: {
      email: email,
      code: Number(code),
    },
  });
}

export async function fetchCheckToken(token) {
  return await axiosRequest({
    method: "POST",
    url: "/auth/me",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchEditUser(user, id) {
  const formData = new FormData();
  formData.append("first_name", user.first_name);
  formData.append("last_name", user.last_name);
  formData.append("email", user.email);
  formData.append("biography", user.biography);
  if (user.first_name.length > 15 || user.last_name.length > 15) {
    return {
      status: "error",
      response: "Votre nom ou prénom est trop long",
    };
  }

  if (user.avatar) {
    if (user.avatar.size > 2000000)
      return { status: "error", response: "Fichier trop volumineux." };
    formData.append("img", user.avatar);
  }
  return await axiosRequest({
    method: "POST",
    url: `auth/update/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
    data: formData,
  });
}

export async function fetchUserInfo(id, token) {
  return await axiosRequest({
    method: "GET",
    url: `/users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function fetchUserPublic(id) {
  return await axiosRequest({
    method: "GET",
    url: `/auth/user/${id}`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}

export async function fetchLogout() {
  return await axiosRequest({
    method: "POST",
    url: `/auth/logout`,
    headers: {
      Authorization: getLocalToken(),
    },
  });
}
