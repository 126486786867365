import { Avatar, Button, Paragraph } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

export const Navbar = ({ user = null, fixedOnScroll = false }) => {
  const history = useHistory();
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    const checkIsFixed = scrollTop > 0;
    if (fixedOnScroll) {
      setIsFixed(checkIsFixed);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {isFixed && <div className="h-[72px] w-full" />}
      <div
        className={joinClasses(
          "w-full py-3",
          isFixed && "fixed top-0 bg-white z-30 shadow-md"
        )}
      >
        <div className="flex justify-between px-10 md:px-16 max-w-[1600px] m-auto items-center">
          <img
            src="/assets/logo.svg"
            alt=""
            className="h-[30px] sm:h-[47px] cursor-pointer"
            onClick={() => history.push("/")}
          />
          {user ? (
            <div
              className="flex gap-5 items-center cursor-pointer"
              onClick={() => history.push("/account")}
            >
              <Paragraph className="underline">{`${
                user.first_name && user.first_name
              } ${user.last_name && user.last_name[0]}.`}</Paragraph>
              <Avatar
                picture={user.img_url ? user.img_url : "/assets/avatar.svg"}
              />
            </div>
          ) : (
            <div className="flex gap-10">
              <Button
                className="!bg-transparent !text-blue"
                onClick={() => history.push("/auth/login")}
              >
                Connexion
              </Button>
              <Button
                className="hidden md:block"
                onClick={() => history.push("/auth/register")}
              >
                Inscription
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
