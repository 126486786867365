import { Paragraph } from "components/atoms";

export const CountInteraction = ({ likes = 0, comments = 0 }) => {
  return (
    <div className="flex gap-5">
      <Paragraph className="flex gap-2">
        {likes}
        <i className="i like" />
      </Paragraph>
      <Paragraph className="flex gap-2">
        {comments}
        <i className="i comment" />
      </Paragraph>
    </div>
  );
};
